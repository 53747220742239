import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Layout from "../../components/layout";
import ReviewSection from "../../components/review-section";
import TextSection from "../../components/text-section";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import ServiceHero from "../../components/service-hero";
import { Helmet } from "react-helmet";
import Certifications from "../../components/certifications";

const FAELPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Services",
				item: {
					url: `${siteUrl}/services`,
					id: `${siteUrl}/services`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Fire Alarms / Emergency Lighting",
				item: {
					url: `${siteUrl}/services/fire-alarms-and-emergency-lighting`,
					id: `${siteUrl}/services/fire-alarm-and-emergency-lighting`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Fire Alarms / Emergency Lighting | Denis Bonnici Electricians Ltd"
				description="Home owners should also consider the installation of smoke/heat detectors to comply with “safety at home”."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/services/fire-alarms-and-emergency-lighting`,
					title:
						"Fire Alarms / Emergency Lighting | Denis Bonnici Electricians Ltd",
					description:
						"Home owners should also consider the installation of smoke/heat detectors to comply with “safety at home”.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
				twitter={{
					handle: "@denisbonnici",
					site: "@denisbonnici",
					cardType: "summary",
				}}
			/>
			<ServiceHero
				backgroundImage={heroImg}
				title="Fire Alarms / Emergency Lighting"
			/>
			<section className="pt-md-7 ">
				<TextSection
					title="Installation of Fire/Smoke Alarms & Emergency Lighting"
					text={
						<span>
							<p>
								We can recommend and install a system to suit your needs or to
								specific specification given by a fire officer/surveyor, to Fire
								regulation BS5839-1: 2017 and Emergency Lighting regulation
								BS5266-1:2016.
							</p>
							<p>
								We are happy to discuss/consult with block management, HMO and
								private landlords to provide a free quotation.
							</p>

							<p>
								Home owners should also consider the installation of smoke/heat
								detectors to comply with “safety at home”.
							</p>
						</span>
					}
				/>
			</section>
			<section className="py-md-7 py-4">
				<TextSection
					title="Testing"
					text={
						<span>
							<p>
								We can recommend and install a system to suit your needs or to
								specific specification given by a fire officer/surveyor, to Fire
								regulation BS5839-1: 2017 and Emergency Lighting regulation
								BS5266-1:2016.
							</p>
							<p>
								We are happy to discuss/consult with block management, HMO and
								private landlords to provide a free quotation.
							</p>

							<p>
								Home owners should also consider the installation of smoke/heat
								detectors to comply with “safety at home”.
							</p>
						</span>
					}
				/>
			</section>
			<section className="pt-6 pb-7 ">
				<Certifications />
			</section>
			<section className="pb-md-7 pb-4">
				<ReviewSection />
			</section>
		</Layout>
	);
};

export default FAELPage;
